<template>
  <b-modal
    v-model="showModal"
    title="Detalhes do Repasse"
    size="xl"
    hide-footer
  >
    <div v-if="remittance">
      <b-row>
        <b-col md="6">
          <p>
            <strong>Status:</strong>
            <span :style="{ color: remittance.status.color_hex }">{{
              remittance.status.name
            }}</span>
          </p>
          <p><strong>Criado por:</strong> {{ remittance.user.full_name }}</p>
          <p>
            <strong>Data do Repasse:</strong>
            {{
              remittance.remittance_date
                ? moment(remittance_date).format("DD/MM/YYYY")
                : "-"
            }}
          </p>
          <p>
            <strong>Data de Criação:</strong>
            {{ formatDate(remittance.created_at) }}
          </p>
        </b-col>
      </b-row>

      <h5 class="mt-3">📦 Pedidos</h5>
      <b-table
        striped
        hover
        small
        :items="remittance.orders"
        :fields="orderFields"
      >
        <template #cell(customer)="data">
          {{ data.item.customer.full_name }}
        </template>
        <template #cell(paymentMethod)="data">
          <i class="bi" :class="data.item.paymentMethod.icon"></i>
          {{ data.item.paymentMethod.name }}
        </template>
        <template #cell(total)="data">
          {{ data.item.total | toCurrency }}
        </template>
        <template #cell(paid_at)="data">
          {{ moment(data.item.paid_at).format("DD/MM/YYYY HH:mm") }}
        </template>
        <template #cell(created_at)="data">
          {{ moment(data.item.created_at).format("DD/MM/YYYY HH:mm") }}
        </template>
      </b-table>

      <div v-if="remittance.medias && remittance.medias.length">
        <h5 class="mt-3">📂 Mídias</h5>
        <b-list-group>
          <b-list-group-item v-for="media in remittance.medias" :key="media.id">
            <a :href="media.url" target="_blank">{{ media.title }}</a>
          </b-list-group-item>
        </b-list-group>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {
  BCol,
  BListGroup,
  BListGroupItem,
  BModal,
  BRow,
  BTable,
} from "bootstrap-vue";
import moment from "moment";

export default {
  components: {
    BModal,
    BListGroup,
    BListGroupItem,
    BTable,
    BRow,
    BCol,
  },
  name: "RemittancePreview",
  props: {
    remittance: {
      type: Object,
      required: true,
    },
    show: Boolean,
  },
  computed: {
    showModal: {
      get() {
        return this.show;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  data() {
    return {
      moment,
      orderFields: [
        { key: "id", label: "ID" },
        { key: "unit.name", label: "Unidade" },
        { key: "customer", label: "Cliente" },
        { key: "paymentMethod", label: "Método de Pagamento" },
        { key: "total", label: "Total" },
        { key: "seller.full_name", label: "Vendedor" },
        { key: "paid_at", label: "Dt Pagamento" },
        { key: "created_at", label: "Dt Criação" },
      ],
    };
  },
  methods: {
    formatDate(date) {
      return new Date(date).toLocaleString("pt-BR");
    },
  },
};
</script>

<style lang="scss" scoped></style>
