var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: { title: "Detalhes do Repasse", size: "xl", "hide-footer": "" },
      model: {
        value: _vm.showModal,
        callback: function ($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal",
      },
    },
    [
      _vm.remittance
        ? _c(
            "div",
            [
              _c(
                "b-row",
                [
                  _c("b-col", { attrs: { md: "6" } }, [
                    _c("p", [
                      _c("strong", [_vm._v("Status:")]),
                      _c(
                        "span",
                        { style: { color: _vm.remittance.status.color_hex } },
                        [_vm._v(_vm._s(_vm.remittance.status.name))]
                      ),
                    ]),
                    _c("p", [
                      _c("strong", [_vm._v("Criado por:")]),
                      _vm._v(" " + _vm._s(_vm.remittance.user.full_name)),
                    ]),
                    _c("p", [
                      _c("strong", [_vm._v("Data do Repasse:")]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.remittance.remittance_date
                              ? _vm
                                  .moment(_vm.remittance_date)
                                  .format("DD/MM/YYYY")
                              : "-"
                          ) +
                          " "
                      ),
                    ]),
                    _c("p", [
                      _c("strong", [_vm._v("Data de Criação:")]),
                      _vm._v(
                        " " +
                          _vm._s(_vm.formatDate(_vm.remittance.created_at)) +
                          " "
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c("h5", { staticClass: "mt-3" }, [_vm._v("📦 Pedidos")]),
              _c("b-table", {
                attrs: {
                  striped: "",
                  hover: "",
                  small: "",
                  items: _vm.remittance.orders,
                  fields: _vm.orderFields,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "cell(customer)",
                      fn: function (data) {
                        return [
                          _vm._v(
                            " " + _vm._s(data.item.customer.full_name) + " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "cell(paymentMethod)",
                      fn: function (data) {
                        return [
                          _c("i", {
                            staticClass: "bi",
                            class: data.item.paymentMethod.icon,
                          }),
                          _vm._v(
                            " " + _vm._s(data.item.paymentMethod.name) + " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "cell(total)",
                      fn: function (data) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("toCurrency")(data.item.total)) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "cell(paid_at)",
                      fn: function (data) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm
                                  .moment(data.item.paid_at)
                                  .format("DD/MM/YYYY HH:mm")
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "cell(created_at)",
                      fn: function (data) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm
                                  .moment(data.item.created_at)
                                  .format("DD/MM/YYYY HH:mm")
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2569241295
                ),
              }),
              _vm.remittance.medias && _vm.remittance.medias.length
                ? _c(
                    "div",
                    [
                      _c("h5", { staticClass: "mt-3" }, [_vm._v("📂 Mídias")]),
                      _c(
                        "b-list-group",
                        _vm._l(_vm.remittance.medias, function (media) {
                          return _c("b-list-group-item", { key: media.id }, [
                            _c(
                              "a",
                              { attrs: { href: media.url, target: "_blank" } },
                              [_vm._v(_vm._s(media.title))]
                            ),
                          ])
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }